import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config = layoutConfig;
  initial = layoutConfig;

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get seniorPrompt() {
    return () => {
      return {
        fr: {
          local: {
            "recommendation.visit": "Qu'y a-t-il à visiter ?",
            "recommendation.activity": "Qu'y a-t-il à faire ?",
            "recommendation.bar": "Peux-tu me recommander un bar ?",
            "recommendation.restaurant":
              "Peux-tu me recommander un restaurant ?",
            "property-services.hotel": "Présente-moi la résidence",
            "property-services.room": "Présente-moi l'appartement",
            "property-services.bar": "Présente-moi le bar",
            "property-services.restaurant": "Présente-moi le restaurant",
            "property-services.businesscenter":
              "Présente-moi le centre de conférence",
            "property-services.pool": "Présente-moi la piscine",
            "property-info.password": "Quel est le mot de passe du wifi ?",
            "property-info.howto": "Comment utiliser le wifi ?",
            "property-info.checkout": "à quelle heure est le checkout ?",
            "property-operating-hours.bar": "Quand ouvre le bar ?",
            "property-operating-hours.hotel": "Quand ouvre l'hôtel ?",
            "property-operating-hours.fitnesscenter":
              "Quand ouvre la salle de sport ?",
            "property-operating-hours.reception": "Quand ouvre la réception ?",
            "property-operating-hours.restaurant":
              "Quand ouvre le restaurant ?",
            "property-location.hotel": "Quel est l'adresse de la résidence ?",
            "property-location.bar": "Où est le bar ?",
            "property-location.restaurant": "Où est le restaurant ?",
            "property-location.reception": "Où est le réception ?",
          },
          maintenance: {
            hairdryer: "Le sèche-cheveux est cassé",
            phone: "Le téléphone ne marche pas",
            tv: "La tv ne fonctionne pas",
            window: "La fenêtre ne s'ouvre pas",
            light: "Une ampoule est cassé",
            watertank: "Il n'y a plus d'eau chaude.",
            electricplate: "La plaque électrique ne fonctionne pas",
            heater: "Le chauffage ne fonctionne pas",
            lock: "La porte/serrure ne s'ouvre pas",
            fridge: "Le frigo ne fonctionne pas",
            furniture: "Un meuble est cassé",
            sink: "L'évier est bouché",
            shower: "La douche est bouchée",
            toilet: "Les toilettes sont bouchés",
          },
          order: {
            newspaper: "Je voudrais un journal",
            food: "Je veux une collation",
            water: "Je veux de l'eau",
            move: "Je veux être déplacé",
            wc: "J'ai besoin d'aller aux toilettes",
            bed: "Je veux aller au lit",
            help: "J'ai besoin d'assistance",
            clean: "Nettoie l'appartement",
            laundry: "Laver mes vétements",
            restaurant: "Réserver au restaurant",
            checkout: "Je libere la chambre",
            checkin: "Je suis dans la chambre",
          },
          vip: {
            introduction: "Welcome Message",
          },
        },
        en: {
          local: {},
          maintenance: {},
          order: {},
          vip: {},
        },
      };
    };
  }

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get hotelPrompt() {
    return () => {
      return {
        fr: {
          local: {
            "recommendation.visit": "Qu'y a-t-il à visiter ?",
            "recommendation.activity": "Qu'y a-t-il à faire ?",
            "recommendation.bar": "Peux-tu me recommander un bar ?",
            "recommendation.restaurant":
              "Peux-tu me recommander un restaurant ?",
            "property-services.hotel": "Présente-moi l'hôtel",
            "property-services.fitnesscenter": "Présente-moi la salle de sport",
            "property-services.room": "Présente-moi la chambre",
            "property-services.spa": "Présente-moi le spa",
            "property-services.bar": "Présente-moi le bar",
            "property-services.restaurant": "Présente-moi le restaurant",
            "property-services.businesscenter":
              "Présente-moi le centre de conférence",
            "property-services.pool": "Présente-moi la piscine",
            "property-info.password": "Quel est le mot de passe du wifi ?",
            "property-info.howto": "Comment utiliser le wifi ?",
            "property-info.checkout": "à quelle heure est le checkout ?",
            "property-operating-hours.bar": "Quand ouvre le bar ?",
            "property-operating-hours.hotel": "Quand ouvre l'hôtel ?",
            "property-operating-hours.spa": "Quand ouvre le SPA ?",
            "property-operating-hours.pool": "Quand ouvre la piscine ?",
            "property-operating-hours.fitnesscenter":
              "Quand ouvre la salle de sport ?",
            "property-operating-hours.businesscenter":
              "Quand ouvre le centre de conférence ?",
            "property-operating-hours.reception": "Quand ouvre la réception ?",
            "property-operating-hours.restaurant":
              "Quand ouvre le restaurant ?",
            "property-location.hotel": "Quel est l'adresse de l'hôtel ?",
            "property-location.bar": "Où est le bar ?",
            "property-location.spa": "Où est le spa ?",
            "property-location.pool": "Où est la piscine ?",
            "property-location.fitnesscenter": "Où est la salle de sport ?",
            "property-location.businesscenter":
              "Où est le centre de conférence ?",
            "property-location.restaurant": "Où est le restaurant ?",
            "property-location.reception": "Où est le réception ?",
          },
          maintenance: {
            hairdryer: "le sèche-cheveux est cassé",
            phone: "le téléphone ne marche pas",
            tv: "la tv ne fonctionne pas",
            window: "la fenêtre ne s'ouvre pas",
          },
          order: {
            shampoo: "Il n'y a plus de shampoing",
            towel: "Je veux de nouvelles serviettes",
            pillow: "Je veux un autre oreiller",
            clean: "Nettoie la chambre",
            toothbrush: "J'ai besoin d'une brosse à dent",
            checkout: "Je libere la chambre",
            checkin: "Je suis dans la chambre",
            laundry: "Laver mes vétements",
            restaurant: "Réserver au restaurant",
          },
          vip: {
            introduction: "Welcome Message",
          },
        },
        en: {
          local: {
            "property-info.password": "What is the wifi password ?",
            "property-info.howto": "How to connect to the network ?",
            "property-info.checkout": "What time is checkout ?",
            "property-services.fitnesscenter":
              "Tell me about the fitness center",
            "property-services.hotel": "Tell me about the hotel",
            "property-services.room": "Tell me about the room",
            "property-services.spa": "Tell me about the spa",
            "property-services.bar": "Tell me about the bar",
            "property-services.businesscenter":
              "Tell me about the conference center",
            "property-services.restaurant": "Tell me about the restaurant",
            "recommendation.visit": "what can I visit ?",
            "recommendation.activity": "what can I do ?",
            "recommendation.bar": "Where can I drink ?",
            "recommendation.restaurant": "Where can I eat ?",
            "property-operating-hours.bar": "When does the bar open ?",
            "property-operating-hours.spa": "When does the spa open ?",
            "property-operating-hours.pool":
              "When does the swimming pool open ?",
            "property-operating-hours.fitnesscenter":
              "When does the fitness center open ?",
            "property-operating-hours.businesscenter":
              "When does the business center open ?",
            "property-operating-hours.reception":
              "When does the reception open ?",
            "property-operating-hours.restaurant":
              "When does the restaurant open?",
            "property-location.hotel": "What is the address of the hotel?",
            "property-location.bar": "Where is the bar ?",
            "property-location.spa": "Where is the spa ?",
            "property-location.pool": "Where is the swimming pool ?",
            "property-location.fitnesscenter": "Where is the fitness center ?",
            "property-location.businesscenter":
              "Where is the business center ?",
            "property-location.restaurant": "Where is the restaurant ?",
            "property-location.reception": "Where is the reception ?",
            "property-operating-hours.hotel": "When does the hotel open ?",
          },
          maintenance: {
            hairdryer: "The hair dryer is broken",
            phone: "The phone is broken",
            tv: "The TV is is broken",
            window: "The window is broken",
          },
          order: {
            shampoo: "There's no more shampoo",
            towel: "I want new towels",
            pillow: "I want another pillow",
            clean: "Clean the room",
            toothbrush: "I want a toothbrush",
            checkout: "Prepare Checkout",
            laundry: "Do my laundry",
            restaurant: "Book at the restaurant",
          },
          vip: {
            introduction: "Welcome Message",
          },
        },
      };
    };
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG](payload): void {
    this.config = payload;
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG]() {
    this.config = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem("config") || "{}")
    );
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
    this.config = merge(this.config, payload);
  }
}
