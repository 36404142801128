import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getCurrentUser, fetchAuthSession, signOut } from "aws-amplify/auth";
import { Cache } from "aws-amplify/utils";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
  tokens: any;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get use role for property
   * @returns boolean
   * */
  get isPropertyAdmin() {
    const currentUser = this.user;
    console.log("--------------------", currentUser.tokens.accessToken.payload);
    return Cache.getItem("property").then((property) => {
      return currentUser.tokens.accessToken.payload["cognito:groups"].includes(
        property.propertyId + "-admin"
      );
    });
  }

  /**
   * Get use role for property
   * @returns boolean
   * */
  get isSuperAdmin() {
    const currentUser = this.user;
    return currentUser.tokens.accessToken.payload["cognito:groups"].includes(
      "admin"
    );
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    //console.log("Mutations.SET_AUTH");
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    //JwtService.saveToken(user.api_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  /*@Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }*/

  @Action
  async [Actions.LOGOUT]() {
    await signOut();
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    /*return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });*/
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    /* return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });*/
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    //console.log("VERIFY_AUTH");
    return fetchAuthSession()
      .then((data) => {
        console.log("VERIFY_AUTH ok", data);
        if (data) {
          this.context.commit(Mutations.SET_AUTH, data);
        }
      })
      .catch((err) => {
        //console.log("VERIFY_AUTH nok");
        this.context.commit(Mutations.SET_ERROR, err);
        this.context.commit(Mutations.PURGE_AUTH);
      });
  }
}
