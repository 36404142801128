<template>
  <Suspense>
    <template #default>
      <router-view />
    </template>
  </Suspense>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    onMounted(async () => {
      //check if current user is authenticated
      Hub.listen("auth", async ({ payload: { event } }) => {
        if (event === "signedIn") {
          router.push({ name: "dashboard" });
        }
        if (event === "signedOut") {
          router.push({ name: "sign-in" });
        }
      });
      if (!store.getters.isUserAuthenticated) {
        try {
          if (document.location.href.indexOf("/upload/") != -1) {
            return;
          }
          const currentUser = await getCurrentUser();
          console.log("getCurrentUser", currentUser);
          if (route.name === undefined) {
            router.push({ name: "dashboard" });
          }
        } catch (e) {
          console.log(e);
          router.push({ name: "sign-in" });
        }
      } else if (
        store.getters.isUserAuthenticated &&
        route.name === undefined
      ) {
        console.log("push dashboard");
      }
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "element-plus/dist/index.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/distribute/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
//@import "~dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>
