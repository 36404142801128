window.global ||= window;

import { createApp } from "vue";
import App from "./App.vue";

import "maz-ui/css/main.css";
import "@vue-js-cron/element-plus/dist/element-plus.css";

import { Amplify } from "aws-amplify";

import { VueReCaptcha } from "vue-recaptcha-v3";
import VueMatomo from "vue-matomo";
import CronElementPlusPlugin from "@vue-js-cron/element-plus";

import amplifyconfig from "./amplifyconfiguration.json";
amplifyconfig.Notifications = {};
amplifyconfig.oauth.redirectSignIn = `${window.location.origin}/`;
amplifyconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(amplifyconfig);

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/i18n";

//import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import "vue-toastification/dist/index.css";

import "bootstrap";
import Toast, { POSITION } from "vue-toastification";

const app = createApp(App);

app.use(ElementPlus);
app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});
app.use(store);
app.use(router);
app.use(CronElementPlusPlugin);

app.use(i18n);
app.use(VueReCaptcha, {
  siteKey: "6LcqERAiAAAAAIF6ooFFtGQd69Tftqy8_sPscUzq",
  loaderOptions: { autoHideBadge: true },
});

app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: "https://shirkalab.matomo.cloud",
  siteId: 3,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: "matomo",
  router: router,
  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,
  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,
  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,
  // Run Matomo without cookies
  // Default: false
  disableCookies: false,
  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  // Whether or not to log debug information
  // Default: false
  debug: false,
  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,
  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,
  domains: undefined,

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: [],
  trackSiteSearch: false,

  // Set this to include crossorigin attribute on the matomo script import
  // Default: undefined, possible values : 'anonymous', 'use-credentials'
  crossOrigin: undefined,
});

//initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
