import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { Cache } from "aws-amplify/utils";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/upload",
    component: () => import("@/components/page-layouts/Anonymous.vue"),
    children: [
      {
        path: "/upload/check",
        name: "upload-check",
        component: () => import("@/views/anonymous/Check.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard_hospitality.vue"),
      },
      {
        path: "/intents",
        name: "intents",
        component: () => import("@/views/Intents.vue"),
      },
      {
        path: "/addressbooks",
        name: "addressbooks",
        component: () => import("@/views/AddressBooks.vue"),
      },
      {
        path: "/rooms",
        name: "rooms",
        component: () => import("@/views/Rooms.vue"),
      },
      {
        path: "/channels",
        name: "channels",
        component: () => import("@/views/Channels.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/Orders.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/Notification.vue"),
      },
      {
        path: "/suggestions",
        name: "suggestions",
        component: () => import("@/views/Suggestion.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/Calendar.vue"),
      },
      {
        path: "/calendar-room",
        name: "calendar-room",
        component: () => import("@/views/CalendarRoom.vue"),
      },
      {
        path: "/reminder",
        name: "reminder",
        component: () => import("@/views/Reminder.vue"),
      },
      {
        path: "/menu",
        name: "menu",
        component: () => import("@/views/Menu.vue"),
      },
      {
        path: "/admin/dashboard",
        name: "admin",
        component: () => import("@/views/admin/Admin.vue"),
      },
      {
        path: "/admin/calendartype",
        name: "calendartype",
        component: () => import("@/views/admin/CalendarType.vue"),
      },
      {
        path: "/admin/menutype",
        name: "menutype",
        component: () => import("@/views/admin/MenuType.vue"),
      },
      {
        path: "/admin/propertypms",
        name: "propertypms",
        component: () => import("@/views/admin/PropertyPms.vue"),
      },
      {
        path: "/admin/activateskill",
        name: "activateskill",
        component: () => import("@/views/admin/ActivateSkill.vue"),
      },
      {
        path: "/admin/propertymedia",
        name: "propertymedia",
        component: () => import("@/views/admin/PropertyMedia.vue"),
      },
      {
        path: "/admin/propertyanalytic",
        name: "propertyanalytic",
        component: () => import("@/views/admin/PropertyAnalytic.vue"),
      },
      {
        path: "/admin/company",
        name: "company",
        component: () => import("@/views/admin/Compagny.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  if (
    to.query !== undefined &&
    to.query.code &&
    to.query.state &&
    to.query.scope
  ) {
    console.log("redirectInfo", to.query);
    await Cache.setItem("redirectInfo", to.query);
  }
  await store.dispatch(Actions.VERIFY_AUTH);

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
